import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {

  constructor(
    protected override readonly router: Router,
    protected override readonly keycloakAngular: KeycloakService
  ) {
    super(router, keycloakAngular);
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if(!this.authenticated){
        await this.keycloakAngular.login(
            {
                redirectUri : window.location.origin + state.url,
            }
        );
    }
    const authenticated = this.keycloakAngular.isLoggedIn();

    if (!authenticated) {
      return this.router.createUrlTree(['']);
    }

    // Check if roles are required for this route
    const requiredRoles = route.data['roles'] as string[];
    if (requiredRoles && requiredRoles.length > 0) {
      const hasRequiredRoles = requiredRoles.every(role => this.roles.includes(role));
      if (!hasRequiredRoles) {
        return this.router.createUrlTree(['/access-denied']); // Redirect to an access denied page
      }
    }

    return true;
  }
}
